import 'isomorphic-unfetch'
import React from 'react'
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  ApolloProvider,
  InMemoryCache
} from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/gatsby'
import { relayStylePagination } from '@apollo/client/utilities'
import { AuthLink, AUTH_TYPE } from 'aws-appsync-auth-link'
import { SentryLink } from 'apollo-link-sentry'
import Auth from '@aws-amplify/auth'
import { Logger } from '@aws-amplify/core'
import { currentCredentials } from './utils/credentials'
import {
  getParams,
  getCookie,
  COOKIE_LOCALE_TO_SCHEMA_LOCALE,
  ANONYMOUS_USER_ID
} from './utils/utils'
import LocalizationProvider from './src/components/LocalizationProvider'
import AdminProvider, { VIEW_AS_USER } from './src/components/AdminProvider'

if (process.env.NODE_ENV === 'development') {
  Logger.LOG_LEVEL = 'DEBUG'
}

const locale =
  COOKIE_LOCALE_TO_SCHEMA_LOCALE[getCookie(document.cookie, 'locale')] ||
  'en_US'
window.locale = locale
const clientMetadata = { locale }

if (window.location.pathname === '/signup' && window.location.search) {
  const queryParams = getParams(window.location.search)
  if (queryParams.userType) {
    clientMetadata.userType = queryParams.userType
  }
  if (queryParams.locale) {
    clientMetadata.locale = queryParams.locale
  }
  if (queryParams.group) {
    clientMetadata.group = queryParams.group
  }
  if (queryParams.cardiologist === 'true') {
    clientMetadata.cardiologist = 'true'
  }
  if (Object.keys(queryParams).includes('demo')) {
    clientMetadata.isDemo = true
  }
}

window.AUTH_CONFIG = Auth.configure({
  region: process.env.Region,
  userPoolId: process.env.CognitoUserPoolUsers,
  userPoolWebClientId: process.env.CognitoUserPoolClientWeb,
  identityPoolId: process.env.CognitoIdentityPoolUsers,
  authenticationFlowType: 'USER_PASSWORD_AUTH', // must in order to enable user migration flow
  mandatorySignIn: false,
  clientMetadata
})

/*
https://github.com/awslabs/aws-mobile-appsync-sdk-js/pull/561#issuecomment-701696316
https://stackoverflow.com/questions/63438293/cannot-connect-apollo-client-to-aws-appsync
https://www.apollographql.com/docs/react/caching/cache-configuration/
https://github.com/apollographql/apollo-client/pull/5146
https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/562
https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/531#issuecomment-657365166 - multiple Auths
https://dev.to/willsamu/how-to-get-aws-appsync-running-with-offline-support-and-react-hooks-678 - support aws-appsync features
*/

const link = ApolloLink.from([
  // not avalilable since the X-Amzn-Trace-Id header is not exposed via the cors header Access-Control-Expose-Headers
  // new ApolloLink((operation, forward) => {
  //   return forward(operation).map((result) => {
  //     Sentry.addBreadcrumb({
  //       category: 'xray',
  //       message:
  //         'X-Amzn-Trace-Id ' +
  //         operation.getContext().response.headers.get('X-Amzn-Trace-Id'),
  //       level: 'info'
  //     })
  //     return result
  //   })
  // }),
  new onError(({ operation, graphQLErrors, networkError }) => {
    Sentry.withScope((scope) => {
      const user = scope.getUser()
      scope.setTransactionName(operation.operationName)
      scope.setContext('apolloGraphQLOperation', {
        operationName: operation.operationName,
        variables: JSON.stringify(operation.variables)
      })
      scope.setLevel('error')

      if (graphQLErrors && graphQLErrors.length) {
        graphQLErrors.forEach(({ errorType, message, locations, path }) => {
          console.log(
            'ERROR LOG - ' +
              message +
              ' - ' +
              JSON.stringify(user) +
              ' - ' +
              user.id
          )
          Sentry.addBreadcrumb({
            category: 'log',
            message: message + ' - ' + JSON.stringify(user) + ' - ' + user.id,
            level: 'info'
          })
          if (
            (!user || user.id === ANONYMOUS_USER_ID) &&
            message === 'AccessDenied'
          ) {
            return
          }
          Sentry.captureMessage(message, {
            fingerprint: ['{{ default }}', '{{ transaction }}'],
            contexts: {
              apolloGraphQLError: {
                errorType,
                message,
                locations: locations ? JSON.stringify(locations) : undefined,
                path
              },
              apolloNetworkError: {
                error: networkError
              }
            }
          })
        })
      } else if (
        networkError &&
        !(
          (!user || user.id === ANONYMOUS_USER_ID) &&
          networkError.message === 'AccessDenied'
        )
      ) {
        Sentry.captureMessage(networkError.message, {
          fingerprint: ['{{ default }}', '{{ transaction }}'],
          contexts: {
            apolloNetworkError: {
              error: networkError
            }
          }
        })
      }
    })
  }),
  new RetryLink({
    attempts: (count, operation, error) => {
      return (
        count <= 3 &&
        (!error ||
          (error.name === 'TypeError' &&
            ['Failed to fetch', 'Load failed'].includes(error.message)))
      )
    },
    delay: (count) => {
      return count * 1000
    }
  }),
  new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...(process.env.NETLIFY && process.env.COMMIT_REF
          ? {
              'X-Correlation-Data': JSON.stringify({
                target: 'Analytics',
                commit: process.env.COMMIT_REF
              })
            }
          : {}),
        ...(VIEW_AS_USER.id ? { 'X-VIEW-AS-USER-ID': VIEW_AS_USER.id } : {})
      }
    }))
    return forward(operation)
  }),
  new AuthLink({
    url: process.env.GraphQlApiUrl,
    region: process.env.Region,
    auth: {
      type: AUTH_TYPE.AWS_IAM,
      credentials: () => currentCredentials()
    }
  }),
  new SentryLink({
    setTransaction: false,
    attachBreadcrumbs: {
      includeQuery: true,
      includeVariables: true,
      includeError: true
    }
  }),
  new HttpLink({
    // uri: `${process.env.AppDomainUrl}/graphql`,
    uri: process.env.GraphQlApiUrl
  })
])

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    possibleTypes: JSON.parse(process.env.GraphqlSchemaPossibleTypes),
    typePolicies: {
      User: {
        fields: {
          address: {
            merge: true
          },
          settings: {
            merge: true
          },
          activityStream: relayStylePagination(),
          auditStream: relayStylePagination(),
          activityAudit: relayStylePagination(),
          logStream: relayStylePagination(),
          activityLog: relayStylePagination()
        }
      },
      Clinic: {
        fields: {
          address: {
            merge: true
          },
          logo: {
            merge: true
          },
          auditStream: relayStylePagination(),
          logStream: relayStylePagination()
        }
      },
      Admin: {
        fields: {
          logByLogGroupId: relayStylePagination()
        }
      },
      StudyData: {
        fields: {
          AnonymousPatientData: {
            merge: true
          },
          patient: {
            merge: true
          }
        },
        keyFields: false
      },
      Study: {
        fields: {
          AnonymousPatientData: {
            merge: true
          },
          patient: {
            merge: true
          },
          auditStream: relayStylePagination(),
          logStream: relayStylePagination()
        }
      },
      ReportData: {
        keyFields: false
      },
      AnonymousPatientData: {
        fields: {
          animal: {
            merge: true
          }
        }
      },
      PatientData: {
        fields: {
          animal: {
            merge: true
          }
        },
        keyFields: false
      },
      Patient: {
        fields: {
          animal: {
            merge: true
          }
        },
        keyFields: false
      },
      CaregiverData: {
        keyFields: false
      },
      GroupData: {
        keyFields: false
      },
      AuditData: {
        keyFields: false
      },
      ActivityData: {
        keyFields: false
      },
      Address: {
        keyFields: false
      },
      File: {
        keyFields: false
      },
      CommentData: {
        keyFields: false
      },
      DiagnosisData: {
        keyFields: false
      },
      AnalysisData: {
        keyFields: false
      },
      MeasurementsData: {
        keyFields: false
      },
      UserMarksData: {
        keyFields: false
      },
      RecordEcgRestData: {
        keyFields: false
      },
      RecordEcgHolterData: {
        keyFields: false
      },
      RecordStethoscopeData: {
        keyFields: false
      },
      RecordSpirometerData: {
        keyFields: false
      },
      RecordQuestionnaireData: {
        keyFields: false
      },
      RecordAttachmentData: {
        keyFields: false
      },
      RecordAttachmentGroupData: {
        keyFields: false
      },
      ReferralsConfigItem: {
        keyFields: false
      },
      ReferralsQueueItem: {
        keyFields: false
      },
      ReferralData: {
        keyFields: false
      },
      Referral: {
        keyFields: false
      },
      CreditCard: {
        keyFields: false
      },
      PaymentToken: {
        keyFields: false
      },
      StudyShareData: {
        keyFields: false
      },
      StudyShareDataInMyStudies: {
        keyFields: ['studyId']
      },
      ShareData: {
        keyFields: false
      },
      PatientConnectionLink: {
        keyFields: false
      },
      CaregiverConnectionLink: {
        keyFields: false
      },
      ClinicConnectionLink: {
        keyFields: false
      },
      GroupConnectionLink: {
        keyFields: false
      },
      ReturnValue: {
        keyFields: false
      },
      DeviceData: {
        keyFields: ['id', 'clinic', ['id']]
      }
    }
  })
})

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <LocalizationProvider>
        <AdminProvider>{element}</AdminProvider>
      </LocalizationProvider>
    </ApolloProvider>
  )
}

export const wrapPageElement = ({ element }) => {
  return <Sentry.ErrorBoundary>{element}</Sentry.ErrorBoundary>
}

export const shouldUpdateScroll = () => {
  return true // needed in order to prevent scroll to top when clicking on context viewer
}

export const onClientEntry = async () => {
  if (window.fetch) {
    await import(`unfetch/polyfill`)
  }
}
